import React, { Component, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { Layout } from './components/Layout';
const Home = React.lazy(() => import('./components/Home'));
const Login = React.lazy(() => import('./components/Login'));
const Helps = React.lazy(() => import('./components/Helps'));
const Help = React.lazy(() => import('./components/Help'));
const Users = React.lazy(() => import('./components/Users'));
const User = React.lazy(() => import('./components/User'));
const FM = React.lazy(() => import('./components/FM'));
const Profile = React.lazy(() => import('./components/Profile'));

class App extends Component {
    constructor(props) {
        super(props);
    }
    getDefaultGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }
    render() {
        return <Layout>
            <Suspense fallback={<div></div>}>
                <Routes basename="/">
                    <Route path="/dashboard" element={<Home />}></Route>
                    <Route path="/login/" element={<Login />}></Route>

                    <Route path="/users/" element={<Users />}></Route>
                    <Route path="/users/new/" element={<User id={this.getDefaultGuid()} />}></Route>
                    <Route path='/users/edit/:id/' element={<User id={1} />} />

                    <Route path="/helps/" element={<Helps />}></Route>
                    <Route path="/help/new/" element={<Help id={this.getDefaultGuid()} />}></Route>
                    <Route path='/help/edit/:id/' element={<Help id={1} />} />

                    <Route path='/fm' element={<FM />} />

                    <Route path='/profile' element={<Profile />} />
                    
                    <Route path="*" element={<Navigate to="/dashboard/" />}></Route>
                </Routes>
            </Suspense>
        </Layout>
    }
}
export default App;