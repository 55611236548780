import React from 'react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import Base from './Base'

export class NavMenu extends Base {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        }
    }
    componentDidMount() {
        this.httpPost("/api/v1/common/get/", {
            id: this.getCurrentFormId()
        })
            .then(res => {
                window.scrollTo(0, 0);
                if (this.isNull(res)) return;
                if (!this.isBlank(res.redirectTo)) this.setState({ redirect: res.redirectTo });
                else {
                    if (res.status === 1) this.setState({ data: res });
                    else this.showErorr(res.message);
                }
            });
    }
    render() {
        if (this.isNull(this.state.data)) return null;

        return <div className="nk-sidebar nk-sidebar-fixed is-dark " id="sideBarMenu" data-content="sidebarMenu">
            <div className="nk-sidebar-element nk-sidebar-head">
                <div className="nk-menu-trigger">
                    <a href="#" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
                    <a href="#" className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
                </div>
                <div className="nk-sidebar-brand">
                    <a href="/" className="logo-link nk-sidebar-logo">
                        <img className="logo-light logo-img" src="/assets/managment/images/logo.png" alt="" />
                        <img className="logo-dark logo-img" src="/assets/managment/images/logo-dark.png" alt="" />
                    </a>
                </div>
            </div>
            <div className="nk-sidebar-element nk-sidebar-body">
                <div className="nk-sidebar-content">
                    <div className="nk-sidebar-menu" data-simplebar>
                        <ul className="nk-menu">
                            <li className="nk-menu-item">
                                <Link to="/dashboard/" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-home"></em></span>
                                    <span className="nk-menu-text">داشبرد</span>
                                </Link>
                            </li>

                            {this.state.data.global.user.userRole == 1 && <li className="nk-menu-item">
                                <Link to="/users/" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-users"></em></span>
                                    <span className="nk-menu-text">کاربران</span>
                                </Link>
                            </li>}
                            {this.state.data.global.user.userRole === 1 && <li className="nk-menu-item">
                                <Link to="/fm/" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-files"></em></span>
                                    <span className="nk-menu-text">مدیریت فایل</span>
                                </Link>
                            </li>}
                            {this.state.data.global.user.userRole == 1 && <li className="nk-menu-item">
                                <Link to="/helps/" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-article"></em></span>
                                    <span className="nk-menu-text">راهنماها</span>
                                </Link>
                            </li>}
                            <li className="nk-menu-item">
                                <Link to="/profile/" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-user-alt"></em></span>
                                    <span className="nk-menu-text">پروفایل</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    }
}