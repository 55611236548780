import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom';
import momentJalaali from 'moment-jalaali';

export default class Base extends Component {
    constructor(props) {
        super(props);
    }
    getDefaultGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }
    getSimanaDomain() {
        return "https://SimanaCore.com" 
    }
    getStartState(){
        return {
            redirect: '',
            res: null,
            loading: true,
            id_to_remove: null,
            page: 1,
            per_page: 10,
            phrase: ''
        }
    }
    FixedPointNumber(e, f = 2) {
        if (e == null || e == undefined || e == "") return 0;
        return parseFloat(e.toFixed(f))
    }
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    getCurrentFormId() {
        var id = this.state.id;
        if (this.state.id == 1) {
            var urlParts = window.location.href.split("/");
            if (window.location.href.endsWith("/"))
                id = urlParts[urlParts.length - 2];
            else
                id = urlParts[urlParts.length - 1];
        }
        return id;
    }
    isBlank(i) {
        if (i == undefined || i == null || i == "") return true;
        return false;
    }
    isNull(i) {
        if (i == undefined || i == null) return true;
        return false;
    }
    isEmpty(i) {
        if (i == undefined || i == null || i == "") return true;
        if (i.length <=0 ) return true;
        return false;
    }
    getToastContainer() {
        return  <ToastContainer
                    position="top-center"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={true}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
    }
    showErorr(msg) {
        toast.error(msg, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }
    showSuccess(msg) {
        toast.success(msg, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }
    httpPost(url, body = {}) {
        if (this.state.loading === 1) return;
        this.state.loading = 1;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                this.state.loading = 0;
                resolve(data);
            })
            .catch(err => {
                this.state.loading = 0;
                this.showErorr("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
                resolve(null);
            });
        });
    }
    renderLoading() {
        return <div className="lockBox">
            <div className="preview-item">
                <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span>لطفا کمی صبر نمایید ...</span>
                </button>
            </div>
        </div>
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    erorrPage(){
        return null;
    }
    redirectTo(e){
        return <Navigate to={e} />
    }
    money(value) {
      var number = typeof value === "number" ? value.toString() : value;
      var separator = ',';
      if (number === null || number === undefined)
        return "";
      var s = number.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + separator);
      return s;
    }
    lockScreen() {
        return <div className="lockBox">
                    <div className="preview-item">
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span>لطفا کمی صبر نمایید ...</span>
                        </button>
                    </div>
                </div>
    }
    logItem(log){
        if (log.action == 1) return <li><span className="badge bg-success">افزودن</span> توسط {log.userName} با آی پی {log.ip} در تاریخ {log.date} {log.note}</li>
        if (log.action == 2) return <li><span className="badge bg-blue">افزودن</span> توسط {log.userName} با آی پی {log.ip} در تاریخ {log.date} {log.note}</li>
        if (log.action == 3) return <li><span className="badge bg-danger">افزودن</span> توسط {log.userName} با آی پی {log.ip} در تاریخ {log.date} {log.note}</li>
        if (log.action == 4) return <li><span className="badge bg-warning">افزودن</span> توسط {log.userName} با آی پی {log.ip} در تاریخ {log.date} {log.note}</li>
        return <p>توسط {log.userName} با آی پی {log.ip} در تاریخ {log.date} {log.note}</p>
    }
    renderLogs(logs){
      if (this.isEmpty(logs)) return null;

      return <div className="card card-bordered">
            <div className="card-inner">
                <div className="card-head">
                    <h5 className="card-title">تاریخچه تغییرات</h5>
                </div>
              <div className="row g-4">
                  {logs.map(log => 
                    <div className="col-lg-12">
                      <div className="form-group">
                              {this.logItem(log)}
                        </div>
                      </div>
                  )}
                </div>
            </div>
        </div>
    }
    getGlobalContainer() {
        return [
                <div className="modal fade" tabindex="-1" id="modalConfirmRemove" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body modal-body-lg text-center">
                                <div className="nk-modal">
                                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                                    <h4 className="nk-modal-title">هشدار حذف اطلاعات</h4>
                                    <div className="nk-modal-text">
                                        <p className="lead">آیا از حذف آیتم انتخابی مطمئن هستید؟</p>
                                        <p className="text-soft">توجه داشته باشید که در صورت حذف اطلاعات دیگر امکان بازیابی اطلاعات وجود ندارد</p>
                                    </div>
                                    <div className="nk-modal-action mt-5">
                                    <a className="btn btn-lg btn-mw btn-danger hand" data-bs-dismiss="modal" onClick={e => { this.remove(); }} style={{"margin-left": "25px"}}>بله - حذف شود</a>
                                        <a className="btn btn-lg btn-mw btn-light hand" data-bs-dismiss="modal" onClick={e => { this.setState({ id_to_remove: null }); }}>خیر - برگشت</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        ];
    }
    confirmRemove(e) {
        this.state.id_to_remove = e;
    }
    remove() {
        this.httpPost("/api/v1/" + this.state.controllerName + "/remove", {
            id: this.state.id_to_remove
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {
                this.showSuccess("اطلاعات با موفقیت حذف گردید");
                this.state.id_to_remove = null;
                this.refreshData()
            }
            else
                this.showErorr(data.message);
        })
        .catch(err => {
            this.showErorr("پاسخی دریافت نشد.لطفا مجدد تلاش نمایید.");
        });
    }
    async refreshData(e) {
        if (!this.isNull(e)) e.preventDefault();
        this.setState({ loading: true });
      
        this.httpPost("/api/v1/" + this.state.controllerName + "/list/", {
            page: this.state.page,
            count: this.state.per_page,
            phrase: this.state.phrase,
            filters: this.state.filters
        })
        .then(data => {
            if (data.status === -1) window.location.href = "/login";
            if (data.status === 1) {

                this.setState({
                    data: data,
                    loading: false
                })
            }
            else {
                this.setState({
                    loading: false
                });
            }
        })
    }
    handlePageChange(page) {
        this.state.page = page;
        this.refreshData();
    }
    handlePerRowsChange(newPerPage) {
        this.state.page = 1;
        this.state.per_page = newPerPage;
        this.refreshData();
    }
createPaging() {
    let total_page = Math.ceil(this.state.data.recordsCount / this.state.per_page);
    let pages = [];
    let start_page = Math.max(1, this.state.page - 5);
    let end_page = Math.min(total_page, this.state.page + 6);

    if (end_page > total_page)
        end_page = total_page;

    if (start_page == end_page && start_page == 1) {

    }
    else {
        if (this.state.page > 1)
            pages.push(<li className="page-item"><a className="page-link hand" onClick={e => this.handlePageChange(this.state.page - 1)}>قبلی</a></li>);

        for (let i = start_page; i <= end_page; i++) {
            pages.push(<li className="page-item"><a className="page-link hand" onClick={e => this.handlePageChange(i)}>{i}</a></li>);
        }

        if (this.state.page < total_page)
            pages.push(<li className="page-item"><a className="page-link hand" onClick={e => this.handlePageChange(this.state.page + 1)}>بعدی</a></li>);
    }        
        return      <div className="card-inner">
                        <div className="nk-block-between-md g-3">
                            <div className="g">
                                <ul className="pagination justify-content-center justify-content-md-start">{ pages }</ul>
                            </div>
                            <div className="g">
                                <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                    <div>تعداد در هر صفحه</div>
                                    <div>
                                        <select className="form-select js-select2" data-search="on" data-dropdown="xs center" onChange={e => this.handlePerRowsChange(parseInt(e.target.value))}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="40">40</option>
                                            <option value="60">60</option>
                                            <option value="80">80</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    }
    genRowNumber(index) {
        index++;
        return ((this.state.page - 1) * this.state.per_page) + index;
    }
    logOut(){
        this.httpPost('/api/v1/logout')
      .then(res => {
          if (res.status === 1)
            window.location.href = "/login/";
      })
      .catch(err=>{
      });
    }

    getFormatedDateForDatePicker(dt, defaultIsNull = null) {
        if (dt === undefined) if (defaultIsNull === null) return null; else return momentJalaali(defaultIsNull);
        if (dt === null) if (defaultIsNull === null) return null; else return momentJalaali(defaultIsNull);
        if (dt === "") if (defaultIsNull === null) return null; else return momentJalaali(defaultIsNull);
        if ((typeof dt) === "object") {
            return dt;
        }
        if ((typeof dt) === "string") {
            dt = this.g2j(dt, 3);
            return momentJalaali(dt, 'jYYYY-jM-jD');
        }
        else {
            return momentJalaali(dt, 'jYYYY-jM-jD');
        }
    }
    //format
    //0 => ۲۰ آذر ۱۳۹۸
    //1 => آذر
    //2 => چهارشنبه، ۷ اسفند، ۱۳۹۸
    //3=>1398/12/25
    //4 => 1398
    //5 => 25
    //6 => چهارشنبه، ۷ اسفند، ۱۳۹۸ ۱۲:۲۰:۳۰
    //7 => 1398/12/25 12:12:12
    g2j(pDate, format = 0) {
        try {
            if (pDate === null || pDate === undefined) return "";
            //وقتی سمت سرور رندر میکنیم این مقدار به صورت یک آبجکت تاریخ هست که بایستی تبدیل کنیم به رشته تاریخی
            if (typeof (pDate) === "string") {
                pDate = new Date(pDate)
            }

            var gy = '';
            var gm = '';
            var gd = '';

            gy = parseInt(pDate.getFullYear());
            gm = parseInt(pDate.getMonth() + 1);
            gd = parseInt(pDate.getDate());

            if (gy === '') return '';
            var g_d_m, jy, jm, jd, gy2, days;
            g_d_m = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
            if (gy > 1600) {
                jy = 979;
                gy -= 1600;
            } else {
                jy = 0;
                gy -= 621;
            }
            gy2 = (gm > 2) ? (gy + 1) : gy;
            days = (365 * gy) + (parseInt((gy2 + 3) / 4)) - (parseInt((gy2 + 99) / 100)) + (parseInt((gy2 + 399) / 400)) - 80 + gd + g_d_m[gm - 1];
            jy += 33 * (parseInt(days / 12053));
            days %= 12053;
            jy += 4 * (parseInt(days / 1461));
            days %= 1461;
            if (days > 365) {
                jy += parseInt((days - 1) / 365);
                days = (days - 1) % 365;
            }
            jm = (days < 186) ? 1 + parseInt(days / 31) : 7 + parseInt((days - 186) / 30);
            jd = 1 + ((days < 186) ? (days % 31) : ((days - 186) % 30));
            if (format === 0) {
                if (jm === 1)
                    return jd + " فروردین " + jy;
                if (jm === 2)
                    return jd + " اردیبهشت " + jy;
                if (jm === 3)
                    return jd + " خرداد " + jy;
                if (jm === 4)
                    return jd + " تیر " + jy;
                if (jm === 5)
                    return jd + " مرداد " + jy;
                if (jm === 6)
                    return jd + " شهریور " + jy;
                if (jm === 7)
                    return jd + " مهر " + jy;
                if (jm === 8)
                    return jd + " آبان " + jy;
                if (jm === 9)
                    return jd + " آذر " + jy;
                if (jm === 10)
                    return jd + " دی " + jy;
                if (jm === 11)
                    return jd + " بهمن " + jy;
                if (jm === 12)
                    return jd + " اسفند " + jy;
            }
            if (format === 1) {
                if (jm === 1)
                    return "فروردین";
                if (jm === 2)
                    return "اردیبهشت";
                if (jm === 3)
                    return "خرداد";
                if (jm === 4)
                    return "تیر";
                if (jm === 5)
                    return "مرداد";
                if (jm === 6)
                    return "شهریور";
                if (jm === 7)
                    return "مهر";
                if (jm === 8)
                    return "آبان";
                if (jm === 9)
                    return "آذر";
                if (jm === 10)
                    return "دی";
                if (jm === 11)
                    return "بهمن";
                if (jm === 12)
                    return "اسفند";
            }
            if (format === 2) {
                let dayOfWeekNum = pDate.getDay();
                let dayName = '';
                if (dayOfWeekNum === 0) dayName = 'یکشنبه';
                if (dayOfWeekNum === 1) dayName = 'دوشنبه';
                if (dayOfWeekNum === 2) dayName = 'سه شنبه';
                if (dayOfWeekNum === 3) dayName = 'چهارشنبه';
                if (dayOfWeekNum === 4) dayName = 'پنجشنبه';
                if (dayOfWeekNum === 5) dayName = 'جمعه';
                if (dayOfWeekNum === 6) dayName = 'شنبه';
                if (jm === 1)
                    return dayName + '، ' + jd + " فروردین، " + jy;
                if (jm === 2)
                    return dayName + '، ' + jd + " اردیبهشت، " + jy;
                if (jm === 3)
                    return dayName + '، ' + jd + " خرداد، " + jy;
                if (jm === 4)
                    return dayName + '، ' + jd + " تیر، " + jy;
                if (jm === 5)
                    return dayName + '، ' + jd + " مرداد، " + jy;
                if (jm === 6)
                    return dayName + '، ' + jd + " شهریور، " + jy;
                if (jm === 7)
                    return dayName + '، ' + jd + " مهر، " + jy;
                if (jm === 8)
                    return dayName + '، ' + jd + " آبان، " + jy;
                if (jm === 9)
                    return dayName + '، ' + jd + " آذر، " + jy;
                if (jm === 10)
                    return dayName + '، ' + jd + " دی، " + jy;
                if (jm === 11)
                    return dayName + '، ' + jd + " بهمن، " + jy;
                if (jm === 12)
                    return dayName + '، ' + jd + " اسفند، " + jy;
            }
            if (format === 3) {
                if (jd < 10) jd = "0" + jd;
                if (jm < 10) jm = "0" + jm;
                return jy + "/" + jm + "/" + jd;
            }
            if (format === 4) {
                return jy;
            }
            if (format === 5) {
                return jd;
            }
            if (format === 6) {
                let dayOfWeekNum = pDate.getDay();
                let dayName = '';
                if (dayOfWeekNum === 0) dayName = 'یکشنبه';
                if (dayOfWeekNum === 1) dayName = 'دوشنبه';
                if (dayOfWeekNum === 2) dayName = 'سه شنبه';
                if (dayOfWeekNum === 3) dayName = 'چهارشنبه';
                if (dayOfWeekNum === 4) dayName = 'پنجشنبه';
                if (dayOfWeekNum === 5) dayName = 'جمعه';
                if (dayOfWeekNum === 6) dayName = 'شنبه';
                var time = pDate.getHours() + ":" + pDate.getMinutes() + ":" + pDate.getSeconds();

                if (jm === 1)
                    return dayName + '، ' + jd + " فروردین، " + jy + " " + time;
                if (jm === 2)
                    return dayName + '، ' + jd + " اردیبهشت، " + jy + " " + time;
                if (jm === 3)
                    return dayName + '، ' + jd + " خرداد، " + jy + " " + time;
                if (jm === 4)
                    return dayName + '، ' + jd + " تیر، " + jy + " " + time;
                if (jm === 5)
                    return dayName + '، ' + jd + " مرداد، " + jy + " " + time;
                if (jm === 6)
                    return dayName + '، ' + jd + " شهریور، " + jy + " " + time;
                if (jm === 7)
                    return dayName + '، ' + jd + " مهر، " + jy + " " + time;
                if (jm === 8)
                    return dayName + '، ' + jd + " آبان، " + jy + " " + time;
                if (jm === 9)
                    return dayName + '، ' + jd + " آذر، " + jy + " " + time;
                if (jm === 10)
                    return dayName + '، ' + jd + " دی، " + jy + " " + time;
                if (jm === 11)
                    return dayName + '، ' + jd + " بهمن، " + jy + " " + time;
                if (jm === 12)
                    return dayName + '، ' + jd + " اسفند، " + jy + " " + time;
            }
            if (format === 7) {
                var timeHour = pDate.getHours();
                var timeMinutes = pDate.getMinutes();
                var timeSeccond = pDate.getSeconds();

                if (timeHour < 10) timeHour = '0' + timeHour;
                if (timeMinutes < 10) timeMinutes = '0' + timeMinutes;
                if (timeSeccond < 10) timeSeccond = '0' + timeSeccond;

                var time = timeHour + ":" + timeMinutes + ":" + timeSeccond;

                if (jd < 10) jd = "0" + jd;
                if (jm < 10) jm = "0" + jm;

                return time + " " + jy + "/" + jm + "/" + jd;
            }
            return jy + "-" + jm + "-" + jd;
        }
        catch (err) { return "" }
    }
}